<template>
  <div>
    <b-card-code>
      <validation-observer ref="AgentEditForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="editAgent">
          <!-- Agent Name -->
          <b-form-group label="Nice Name" label-for="nice_name">
            <template #label>
              <span>Nice Name</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <b-form-input id="nice_name" v-model="niceName" name="nice_name" />
          </b-form-group>
          <b-form-group label="Credit per Scan" label-for="credit_per_scan">
            <template #label>
              <span>Credit per Scan</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <b-form-input
              type="number"
              id="credit_per_scan"
              v-model="creditPerScan"
              name="credit_per_scan"
              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
            />
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="invalid"
            class="my-1"
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Update</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      niceName: "",
      creditPerScan: "",
      isloading: false,
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "agent/type/" + this.$route.params.id,
      };
      this.$http(options).then((res) => {
        this.niceName = res.data.agent_type_nicename;
      });
    },
    editAgent: function () {
      this.isloading = true;
      //call to axios
      console.log("success");
      var self = this;
      let data = {
        agent_type_nicename: this.niceName,
        credit_per_scan: this.creditPerScan,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "agent/type/" +
          this.$route.params.id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.agent_type_id) {
          this.$router.push({ name: "Agent List" });
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Edited Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.isloading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
